<template>
  <v-app style="background: #121212">
    <v-system-bar height="30" dark>
      <div>
        <v-icon small>mdi-qrcode-scan</v-icon>
        Scanner • Desconectado
      </div>
      <v-spacer></v-spacer>
      <span>{{ new Date() | date("DD [de] MMMM") }}</span>
    </v-system-bar>
    <v-theme-provider dark>
      <div>
        <Scanner
          :disallowMethods="['nfc']"
          :paused="disabled"
          @scan="onScan"
          loading
          style="position: fixed; top: 30px; left: 0; right: 0; bottom: 0"
        />
        <div style="position: fixed; bottom: 10px; left: 10px; right: 10px">
          <v-alert
            v-if="lastSession"
            dense
            color="primary"
            class="mb-2 mx-2 rounded-lg"
          >
            <div class="d-flex gap-2">
              <v-icon>mdi-history</v-icon>
              <div class="flex-grow-1">Reconectar a última sessão</div>
              <v-btn small @click="connectLastSession"> Conectar </v-btn>
            </div>
          </v-alert>
          <v-card class="pa-4">
            <h6 class="text-center mb-0">
              Leia o QR Code para iniciar a sessão
            </h6>
            <v-alert
              v-if="error"
              type="error"
              dense
              text
              class="mt-4 mb-0"
              transition="slide-y-transition"
            >
              {{ error }}
            </v-alert>
          </v-card>
        </div>
      </div>
    </v-theme-provider>
  </v-app>
</template>

<script>
import moment from "moment";
import Scanner from "../../../components/global/scanner/Scanner.vue";
export default {
  name: "ScannerInit",
  metaInfo: {
    title: "Scanner",
  },
  data: () => ({
    error: null,
    disabled: false,
    lastSession: null,
  }),
  components: {
    Scanner,
  },
  methods: {
    onScan(data) {
      console.log(data);
      const { value } = data;
      // composition: window.location.origin/staff/scanner/ base64 Session
      const regex = new RegExp(
        `^${window.location.origin}/staff/scanner/([a-zA-Z0-9+/=]+)$`
      );
      const match = value.match(regex);
      if (match) {
        const session = match[1];
        this.disabled = true;

        this.$router.replace({
          name: "staff.scanner.session",
          params: { session },
        });
      } else {
        this.error = "QR Code inválido";
        setTimeout(() => {
          this.error = null;
        }, 3000);
      }
    },
    loadLastSession() {
      const lastSession = localStorage.getItem("staff:scanner");
      if (lastSession) {
        const session = JSON.parse(lastSession);
        const valid = this.lastSessionStatus(session);
        if (!valid) {
          localStorage.removeItem("staff:scanner");
          return;
        }
        this.lastSession = session;
        console.log(this.$route.query);
        if (this.$route.query.autoConnect === false) return;

        this.connectLastSession();
      }
    },
    connectLastSession() {
      if (!this.lastSession) return;
      this.$router.replace({
        name: "staff.scanner.session",
        params: { session: this.lastSession.token },
      });
    },
    lastSessionStatus(session) {
      const savedAt = moment(session.savedAt);
      if (moment().diff(savedAt, "hours") > 12) return false;
      if (session.startDate && new Date(session.startDate) > new Date())
        return false;
      if (session.endDate && new Date(session.endDate) < new Date())
        return false;
      return true;
    },
  },
  mounted() {
    this.loadLastSession();
  },
};
</script>

<style>
</style>